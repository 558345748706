import { Link } from 'react-router-dom'

const Footer = ({ navigation }) => {
  return (
    <footer className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.footer.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link
                to={item.href}
                className="text-base text-gray-500 hover:text-gray-900"
              >
                {item.name}
              </Link>
            </div>
          ))}
        </div>
        <div className="mt-4 md:mt-0 md:order-1">
          <p className="text-center text-sm text-gray-400">
            &copy; {new Date().getFullYear()} Ortho Corp GmbH
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
