import {
  BadgeCheckIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "Individuell",
    description:
      "Maßangefertigte Orthesen sind orthopädische Hilfsmittel und werden angewandt, wenn konfektionierte nicht  ausreichen. Orthesen für Hand, Fuß oder Knie werden genau auf Ihre Bedürfnisse abgestimmt.",
    icon: UserIcon,
  },
  {
    name: "Kompetent",
    description:
      "Es gibt Diagnosen, bei denen z.B. anatomische Einzigartigkeiten berücksichtigt werden müssen. Dies kann sowohl bei angeborenen als auch erworbenen Erkrankungen der Fall sein.",
    icon: LightBulbIcon,
  },
  {
    name: "Qualitativ",
    description:
      "Wir bieten Ihnen nur hochwertige Produkte an, von deren Qualität wir uns selbst überzeugt haben. Hierbei nehmen wir uns Zeit für Ihre individuelle Beratung.",
    icon: BadgeCheckIcon,
  },
  {
    name: "Professionell",
    description:
      "Sie erhalten keine Lösung „von der Stange“, sondern immer ein Produkt, das genau zu Ihren Anforderungen passt. Dabei arbeiten wir eng mit Ärzten und Krankenkassen zusammen.",
    icon: ShieldCheckIcon,
  },
];

const Orthesen = () => {
  return (
    <div className="py-12 sm:py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-12">
        <div className="lg:text-center">
          <p className="text-3xl leading-8 font-extrabold tracking-tight text-[#1B3F4B] sm:text-4xl">
            Maßangefertigte Orthesen
          </p>
        </div>

        <div className="mt-12 md:mt-16">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-[#1B3F4B]">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Orthesen;
