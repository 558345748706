import manProthese from "../assets/manProthese.jpg";

const Prothetik = () => {
  return (
    <div name="prothetik" class="relative">
      <div class="max-w-7xl mx-auto py-12 sm:py-16 sm:px-12 px-4">
        <svg
          className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="f210dbf6-a58d-4871-961e-36d5016a0f49"
              x={2}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
          />
        </svg>
        <div class="lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="relative">
            <h2 class="text-3xl font-extrabold text-[#1B3F4B]">
              Herstellungsprozess Prothetik der unteren Extremität
            </h2>
            <p class="mt-4 text-base text-gray-500 sm:text-lg">
              <span className="text-[#61AEC8]"> Eines vorab:</span> Es gibt
              nicht „den“ Behandlungsablauf bei Prothesen. Jede Patientin und
              jeder Patient kommt mit einer anderen Ausgangssituation.
            </p>
            <p class="mt-4 text-base text-gray-500 sm:text-lg">
              Deshalb kann der im folgenden dargestellte „klassische” Ablauf
              allenfalls typisch sein.
            </p>
            <img
              className="w-full sm:h-72 h-48 lg:h-48 mt-8 object-cover rounded-lg"
              src={manProthese}
              alt=""
            />
          </div>
          <div class="relative mt-8 lg:mt-0 lg:col-span-2">
            <ol class="relative border-l border-[#61AEC8]">
              <li class="mb-10 ml-4">
                <div class="absolute w-3 h-3 bg-[#1B3F4B] rounded-full mt-1.5 -left-1.5 border border-[#1B3F4B]"></div>
                <h3 class="text-lg font-medium text-[#1B3F4B]">Schritt 1</h3>
                <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  Nach dem Erhalt einer ärztlichen Verordnung lade ich Sie gerne
                  zu einem Beratungsgespräch ein. Bei diesem Gespräch wird beim
                  Erhalt einer Vorversorgung anhand eines Anamnesebogens
                  festgestellt, ob die prothetische Versorgung ausreichend ist.
                </p>
                <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  Dazu dient die Aufnahme des Muskel- und Gelenkstatus nach
                  Janda, um den Mobilitätsgrad zu ermitteln. Falls keine
                  Vorversorgung stattgefunden hat, wird mit einer
                  Interimsprothese (vorübergehende Prothese für sechs bis sieben
                  Monate) oder einer Anschlussversorgung (Definitivprothese)
                  dieselbe Vorgehensweise angewandt.
                </p>
              </li>
              <li class="mb-10 ml-4">
                <div class="absolute w-3 h-3 bg-[#1B3F4B] rounded-full mt-1.5 -left-1.5 border border-[#1B3F4B]"></div>
                <h3 class="text-lg font-medium text-[#1B3F4B]">Schritt 2</h3>
                <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  Anhand dieses Gesprächs und der ersten Einschätzung werden die
                  zu ermittelnden Mobilitätsgrade, die Struktur und die
                  Passteile für die Prothese ausgewählt. Danach werden diese
                  Daten in Form von einem Kostenvoranschlag an die Krankenkasse
                  geschickt. Nach Erhalt der Genehmigung seitens der Krankasse
                  kommt es zum nächsten Termin, wo ein Gipsabdruck gemacht wird.
                </p>
                <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  Das Gipsnegativ wird mit Gips ausgegossen und von dem
                  zuständigen Techniker modelliert. Sobald das Gipspositiv
                  erstellt ist, wird zunächst ein Probeschaft tiefgezogen.
                  Hierbei wird eine Plexiglasplatte in einem Ofen erhitzt und
                  über den Gips geformt.
                </p>
              </li>
              <li class="mb-10 ml-4">
                <div class="absolute w-3 h-3 bg-[#1B3F4B] rounded-full mt-1.5 -left-1.5 border border-[#1B3F4B]"></div>
                <h3 class="text-lg font-medium text-[#1B3F4B]">Schritt 3</h3>
                <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  Dieser Probeschaft wird zunächst frei gesägt, beschliffen und
                  dann aufgebaut. Anschließend wird der Schaftansatz nach den
                  Aufbaurichtlinien des Herstellers angebracht und die
                  Strukturteile darunter geschraubt. Die erste Anprobe erfolgt,
                  wenn möglich in den Räumlichkeiten der Firma, da es sich
                  erstmals um eine Passformkontrolle handelt.
                </p>
                <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  Während der ersten statischen Anprobe folgt die dynamische
                  Anprobe, wobei der Techniker den Aufbau der Prothese
                  korrigiert, um das Gangbild des Kunden zu optimieren und ggf.
                  entstandene Kontrakturen zu kompensieren. Wenn alles optimal
                  funktioniert, kommt der letzte Arbeitsschritt.
                </p>
              </li>
              <li class="ml-4">
                <div class="absolute w-3 h-3 bg-[#1B3F4B] rounded-full mt-1.5 -left-1.5 border border-[#1B3F4B]"></div>
                <h3 class="text-lg font-medium text-[#1B3F4B]">Schritt 4</h3>
                <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                  Dabei handelt es sich um die Vorbereitung einer
                  Interimsprothese, wo der Probeschaft verstärkt wird, damit
                  Korrekturen am Prothesenschaft möglich sind. Bei der
                  Definitivprothese wird der Probeschaft kopiert und der
                  Gussschaft hergestellt.
                </p>
                <p class="text-base font-normal text-gray-500 dark:text-gray-400">
                  Von einem Gussschaft spricht man, wenn dieser beispielsweise
                  aus Carbon und Glasschläuchen getränkt wird und
                  mit Laminierharz hergestellt wurde. Während der Versorgung
                  kommt es zu mehreren Kontrollterminen, um Fehler seitens des
                  Technikers auszuschließen oder bei Fortschritten des Kunden
                  die Prothese nachzubessern.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prothetik;
