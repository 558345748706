import { Fragment } from "react";
import { Tab } from "@headlessui/react";

import wrist from "../assets/Wrist.svg";
import elbow from "../assets/Elbow.svg";
import back from "../assets/Back.svg";
import knee from "../assets/Knee.svg";
import foot from "../assets/Foot.svg";

import wristOrthosis from "../assets/wristOrthosis.jpg";
import wristOrthosis2 from "../assets/wristOrthosis-2.jpg";

import elbowOrthosis from "../assets/elbowOrthosis.jpg";
import elbowOrthosis2 from "../assets/elbowOrthosis-2.jpg";

import backOrthosis from "../assets/backOrthosis.jpg";
import backOrthosis2 from "../assets/backOrthosis-2.jpg";

import kneeOrthosis from "../assets/kneeOrthosis.jpg";
import kneeOrthosis2 from "../assets/kneeOrthosis-2.jpg";

import footOrthosis from "../assets/footOrthosis.jpg";
import footOrthosis2 from "../assets/footOrthosis-2.jpg";

const solutions = [
  {
    name: "Handgelenk",
    preview:
      "Arbeiten, essen oder schreiben – unsere Hände sind täglich im Dauereinsatz.",
    navIcon: wrist,
    features: [
      {
        name: "Entlasten und Schmerzen lindern",
        description:
          "Arbeiten, essen oder schreiben – unsere Hände sind täglich im Dauereinsatz. Das führt nicht selten zur Überlastung des Handgelenkes. Die Folge sind Reizungen oder gar Entzündungen, wie z.B. Sehnenscheidenentzündungen.",
        description2:
          "Mit der richtigen Bandage oder Orthese können wir Ihr Handgelenk entlasten und Schmerzen lindern. Auch für die Genesung ist die Ruhigstellung und Entlastung von essentieller Wichtigkeit.",
        imageSrc: wristOrthosis,
        imageSrc2: wristOrthosis2,
      },
    ],
  },
  {
    name: "Ellenbogen",
    preview: "Der Ellenbogen ermöglicht viele Bewegungen unseres Arms.",
    navIcon: elbow,
    features: [
      {
        name: "„Golferarm“ und „Tennisarm“?",
        description:
          "Der Ellenbogen ermöglicht viele Bewegungen unseres Arms. Dabei ist er nicht selten hohen Belastungen ausgesetzt, z.B. beim Sport oder bei körperlicher Arbeit. Das kann auf Dauer zu Reizungen oder Entzündungen führen.",
        description2:
          "Dabei sind „Epicondilitis radialis und ulnaris“, umgangssprachlich auch „Golferarm“ und „Tennisarm“ genannt, die häufigste Diagnose. Mit Bandagen oder Spangen können Sie entlastend vorsorgen. Auch bei größeren Verletzungen – wie Frakturen (Brüchen) – sind unsere passgenauen Orthesen sehr hilfreich.",
        imageSrc: elbowOrthosis,
        imageSrc2: elbowOrthosis2,
      },
    ],
  },

  {
    name: "Fuß",
    preview:
      "Laufen, stehen, springen – der Fuß und damit das Sprunggelenk wird vielfältig beansprucht.",
    navIcon: foot,
    features: [
      {
        name: "Stabilisieren und schützen",
        description:
          "Laufen, stehen, springen – der Fuß und damit das Sprunggelenk wird vielfältig beansprucht. Spezielle Bandagen stabilisieren das Sprunggelenk und schützen den Knöchel.",
        description2:
          "Nach einer Verletzung – etwa durch „Umknicken“ des Fußes oder bei hoher Belastung der Bänder und Sehnen beim Sport – unterstützen unsere Bandagen die Regeneration und lindern den Schmerz. Mit hochwertigen Einlagen können wir stützend und bettend ihre Füße kräftigen.",
        imageSrc: footOrthosis,
        imageSrc2: footOrthosis2,
      },
    ],
  },
  {
    name: "Knie",
    preview:
      "Das Kniegelenk ist das Gelenk unseres Körpers, das unter höchster Belastung steht.",
    navIcon: knee,
    features: [
      {
        name: "Beschwerden, wie Arthrose?",
        description:
          "Das Kniegelenk ist das Gelenk unseres Körpers, das unter höchster Belastung steht. In Folge dessen sind schmerzhafte Beschwerden, wie Arthrose, nicht selten. Das Kniegelenk ist auch bei sportlicher Betätigung stark gefährdet – z.B. durch Bänder- und Meniskusschäden. Dadurch wird die Bewegung eingeschränkt und der Alltag erschwert. Umso wichtiger ist die richtige Versorgung. ",
        description2:
          "Wir beraten Sie, damit Sie schnell wieder genesen und unterstützen den Prozess mit Hartrahmen Orthesen oder Bandagen, die das Kniegelenk entlasten und führen.",
        imageSrc: kneeOrthosis,
        imageSrc2: kneeOrthosis2,
      },
    ],
  },
  {
    name: "Rücken",
    preview:
      "Stehend oder sitzend – unser Rücken trägt stets die Hauptlast unseres Körpers.",
    navIcon: back,
    features: [
      {
        name: "Falsches Heben oder langes Sitzen",
        description:
          "Stehend oder sitzend – unser Rücken trägt stets die Hauptlast unseres Körpers. Wenn dann noch Fehlbelastungen hinzukommen – z.B. durch falsches Heben oder langes Sitzen auf ergonomisch schlechten Stühlen – dann macht sich das schnell auf unangenehme Weise bemerkbar. ",
        description2:
          "Nicht selten kommt es zu andauernden Schmerzen, die das Leben beeinträchtigen. Durch stützende und stabilisierende Orthesen oder Bandagen wird der Rücken entlastet und Ihr Alltag wieder beschwerdefreier.",
        imageSrc: backOrthosis,
        imageSrc2: backOrthosis2,
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Orthopaedietechnik = () => {
  return (
    <div name="orthopaedietechnik" className="relative bg-gray-50">
      <section
        aria-labelledby="features-heading"
        className="mx-auto py-12 sm:py-16 sm:px-12 px-4"
      >
        <div className="max-w-3xl">
          <h2
            id="features-heading"
            className="text-3xl font-extrabold tracking-tight text-[#1B3F4B] sm:text-4xl"
          >
            Bandangen & Orthesen
          </h2>
        </div>

        <Tab.Group as="div" className="mt-4">
          <div className="-mx-4 flex overflow-x-auto sm:mx-0">
            <div className="flex-auto px-4 border-b border-gray-200 sm:px-0">
              <Tab.List className="-mb-px flex space-x-10">
                {solutions.map((tab) => (
                  <Tab
                    key={tab.name}
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "border-[#61AEC8] text-[#61AEC8] focus:outline-none"
                          : "border-transparent text-gray-500 hover:text-[#1B3F4B] hover:border-gray-300",
                        "whitespace-nowrap py-3 border-b-2 font-medium text-sm"
                      )
                    }
                  >
                    {tab.name}
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>

          <Tab.Panels as={Fragment}>
            {solutions.map((tab) => (
              <Tab.Panel key={tab.name} className="space-y-16 pt-6 lg:pt-12">
                {tab.features.map((feature) => (
                  <div
                    key={feature.name}
                    className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8"
                  >
                    <div className="mt-6 lg:mt-0 lg:col-span-5">
                      <h3 className="text-lg font-medium text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="mt-2 text-base text-gray-500">
                        {feature.description}
                      </p>
                      <p className="mt-4 text-base text-gray-500">
                        {feature.description2}
                      </p>
                    </div>
                    <div className="lg:col-span-7">
                      <div className="grid grid-cols-2 gap-6 lg:gap-14">
                        <img
                          src={feature.imageSrc}
                          alt=""
                          className="bg-gray-100 rounded-lg"
                        />
                        <img
                          src={feature.imageSrc2}
                          alt=""
                          className="bg-gray-100 rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </section>
    </div>
  );
};

export default Orthopaedietechnik;
