import womanWheelchair from "../assets/womanWheelchair.jpg";

const Rehatechnik = () => {
  return (
    <div
      name="rehatechnik"
      className="relative mt-4 lg:my-16 bg-gradient-to-r from-teal-500 to-cyan-600 lg:z-10 lg:relative xl:rounded-lg"
    >
      <div className="lg:mx-auto lg:max-w-7xl lg:px-12 lg:grid lg:grid-cols-3 lg:gap-8">
        <div className="relative lg:-my-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-1/2 bg-gray-50 lg:hidden"
          />
          <div className="mx-auto pt-4 px-4 max-w-7xl sm:px-12 lg:p-0 lg:h-full">
            <div className="aspect-w-10 aspect-h-4 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-5 lg:aspect-none lg:h-full">
              <img
                className="object-cover lg:h-full lg:w-full"
                src={womanWheelchair}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="pb-12 sm:pb-16 pt-8 lg:pt-16 lg:m-0 lg:col-span-2 lg:pl-8">
          <div className="mx-auto px-4 sm:px-12 lg:px-0">
            <div>
              <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
                Reha-Mittel
              </h2>
              <p className="mt-6 text-base text-white">
                Die Auswahl an Reha-Mitteln ist vielfältig und reicht von Dusch-
                und Badehilfen über Badewannenlifter und Toilettenstühlen bis
                hin zu Gehstöcken, Rollatoren und Rollstühlen. Der Bedarf
                richtet sich immer nach Ihren individuellen Bedürfnissen.
              </p>
              <p className="mt-6 text-base text-white">
                Wir beraten Sie bei der Auswahl und unterstützen Sie ggfs. bei
                der Beantragung der Kostenübernahme durch die Krankenkasse.
                Reha-Mittel, die stationär vewendet werden – wie z.B.
                Bewegungstrainer, Pflegebetten, Aufstehhilfe oder Duschhocker –
                liefern wir Ihnen gerne nach Hause und installieren diese –
                falls nötig – fachgerecht.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rehatechnik;
