import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";

import App from "./App";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";

ReactDOM.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route path="/impressum" element={<Impressum />} />
      <Route path="/datenschutz" element={<Datenschutz />} />
    </Routes>
  </Router>,
  document.getElementById("root")
);
console.log("revert")