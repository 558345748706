import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-scroll";

import Orthopaedietechnik from "../src/components/Orthopaedietechnik";
import Orthesen from "../src/components/Orthesen";
import Rehatechnik from "../src/components/Rehatechnik";
import Kontakt from "../src/components/Kontakt";
import Footer from "../src/components/Footer";

import { MenuIcon, XIcon, PhoneIcon } from "@heroicons/react/outline";
import { ChevronRightIcon } from "@heroicons/react/solid";

import logo from "../src/assets/logo.svg";

import bortLogo from "../src/assets/bort-logo.svg";
import thuasneLogo from "../src/assets/thuasne-logo.svg";
import spannritLogo from "../src/assets/spannrit-logo.svg";
import mediLogo from "../src/assets/medi-logo.svg";
import russkaLogo from "../src/assets/russka-logo.svg";

import measureLeg from "../src/assets/measureLeg.jpg";
import Prothetik from "./components/Prothetik";

const navigation = {
  main: [
    { name: "Orthopädietechnik", href: "orthopaedietechnik" },
    { name: "Prothetik", href: "prothetik" },
    { name: "Rehatechnik", href: "rehatechnik" },
    { name: "Kontakt", href: "kontakt" },
  ],
  footer: [
    { name: "Impressum", href: "/impressum" },
    { name: "Datenschutz", href: "/datenschutz" },
  ],
};

function App() {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto overflow-hidden">
        <div className="relative pt-6">
          <svg
            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={2}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={-2}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
            />
          </svg>
          <Popover>
            <nav
              className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-12"
              aria-label="Global"
            >
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link to="#">
                    <span className="sr-only">OrthoCorp</span>
                    <img
                      className="h-8 w-auto sm:h-10 cursor-pointer"
                      src={logo}
                      alt=""
                    />
                  </Link>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="hidden space-x-10 md:flex md:ml-10">
                  {navigation.main.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      smooth={true}
                      duration={500}
                      className="font-medium text-gray-500 hover:text-gray-900 cursor-pointer"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <a
                href="tel:023637286590"
                className="lg:hidden md:inline-flex hidden items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-[#1B3F4B] hover:bg-[#265869] focus:outline-none"
              >
                <PhoneIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              <div className="md:hidden lg:flex">
                <a
                  href="tel:023637286590"
                  className="hidden lg:inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-[#1B3F4B] hover:bg-[#265869]"
                >
                  <PhoneIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Jetzt Anrufen
                </a>
              </div>
            </nav>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={logo} alt="" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 space-y-1">
                    {navigation.main.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        smooth={true}
                        duration={500}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 cursor-pointer"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="py-4 px-4">
                    <a
                      href="tel:023637286590"
                      class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#1B3F4B] hover:bg-[#265869]"
                    >
                      Jetzt Anrufen
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mx-auto pt-16 pb-12 lg:pt-24 lg:pb-16 sm:px-12 px-4">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="relative md:mx-auto lg:col-span-6 sm:text-left text-center">
                <Link
                  to="kontakt"
                  smooth={true}
                  duration={500}
                  className="bg-[#1b3f4b] inline-flex items-center rounded-full p-1 pr-2 sm:text-center xl:text-base lg:text-sm text-gray-200 hover:text-white cursor-pointer"
                >
                  <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-[#61AEC8] rounded-full">
                    Beratung
                  </span>
                  <span className="ml-4 text-sm">Jetzt vereinbaren</span>
                  <ChevronRightIcon
                    className="ml-2 w-5 h-5 text-gray-200"
                    aria-hidden="true"
                  />
                </Link>

                <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-[#1B3F4B] sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                  <span className="lg:block sm:inline block">
                    Ihr Fachpartner
                  </span>{" "}
                  <span className="text-[#61AEC8] lg:block sm:inline block">
                    in Datteln.
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 md:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  Mit unserem Angebot möchten wir dazu beitragen, Ihre
                  Beschwerden zu lindern, Ihre Lebensqualität zu steigern und
                  Ihre Genesung zu fördern.
                </p>
                <p className="mt-8 text-sm text-[#1B3F4B] uppercase tracking-wide font-semibold sm:mt-10">
                  Unsere starken Partner
                </p>
                <div className="mt-5 w-full sm:max-w-lg lg:ml-0">
                  <div className="flex flex-wrap items-start justify-between">
                    <div className="flex justify-center px-1">
                      <img src={bortLogo} alt="" className="h-9 sm:h10" />
                    </div>
                    <div className="flex justify-center px-1">
                      <img src={thuasneLogo} alt="" className="h-9 sm:h10" />
                    </div>
                    <div className="flex justify-center px-1">
                      <img src={spannritLogo} alt="" className="h-9 sm:h10" />
                    </div>
                    <div className="flex justify-center px-1">
                      <img src={mediLogo} alt="" className="h-9 sm:h10" />
                    </div>
                    <div className="flex justify-center px-1">
                      <img src={russkaLogo} alt="" className="h-9 sm:h10" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative lg:mt-0 mt-12 sm:mx-auto  lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <div className="relative mx-auto lg:ml-16 w-full sm:h-72 h-48 rounded-lg shadow-lg">
                  <img
                    className="w-full h-full object-cover rounded-lg"
                    src={measureLeg}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
        <Orthopaedietechnik />
        <Prothetik />
        <Orthesen />
        <Rehatechnik />
        <Kontakt />
        <Footer navigation={navigation} />
      </div>
    </div>
  );
}

export default App;
