import { useEffect } from "react";

const Impressum = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative sm:px-12 px-4 max-w-5xl mx-auto overflow-hidden">
        <div className="text-lg mx-auto">
          <h1>
            <span className="mt-2 text-3xl lg:text-center leading-8 font-extrabold tracking-tight text-[#1B3F4B] sm:text-4xl">
              Impressum
            </span>
          </h1>
        </div>
        <div className="mt-4 text-gray-500 mx-auto">
          <h4 className="text-[#1B3F4B] font-bold">Angaben gemäß § 5 TMG</h4>
          <p className="text-sm mt-2">
            OrthoCorp GmbH<span className="block">Castroper Straße 43</span>
            <span className="block">45711 Datteln</span>
          </p>
          <p className="text-sm mt-4">
            Handelsregister: HRB 9000
            <span className="block">
              Registergericht: Amtsgericht Recklinghausen
            </span>
          </p>
          <p className="text-sm text-[#1B3F4B] font-bold mt-4">
            Vertreten durch:
            <span className="block text-gray-500 font-normal">
              Selim Yildirim
            </span>
          </p>
        </div>
        <div className="mt-4 text-gray-500 mx-auto">
          <h4 className="text-[#1B3F4B] font-bold">Kontakt</h4>
          <p className="text-sm mt-2">
            Telefon:{" "}
            <a href="tel:+4923637286590" className="hover:text-[#61AEC8]">
              +49 2363 7286590
            </a>
            <span className="block">
              E-Mail:{" "}
              <a
                href="mailto:info@ortho-corp.de"
                className="hover:text-[#61AEC8]"
              >
                info@ortho-corp.de
              </a>
            </span>
          </p>
        </div>
        <div className="mt-4 text-gray-500 mx-auto">
          <h4 className="text-[#1B3F4B] font-bold">Umsatzsteuer-ID</h4>
          <p className="text-sm mt-2">
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            <span className="block">DE347528542</span>
          </p>
        </div>
        <div className="mt-4 text-gray-500 mx-auto">
          <h4 className="text-[#1B3F4B] font-bold">
            Verbraucherstreitbeilegung/Universalschlichtungsstelle
          </h4>
          <p className="text-sm mt-2">
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </div>
        <div className="mt-4 text-gray-500 mx-auto">
          <h4 className="text-[#1B3F4B] font-bold">Kontakt</h4>
          <p className="text-sm mt-2">
            Quelle:
            <span className="block">
              <a
                href="https://www.e-recht24.de"
                className="hover:text-[#61AEC8]"
              >
                https://www.e-recht24.de
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
