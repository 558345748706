import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";

const Kontakt = ({ navigation }) => {
  const [showModal, setShowModal] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE,
        form.current,
        process.env.REACT_APP_EMAIL_JS_USER
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          setShowModal(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div name="kontakt" className="relative">
      <div className="relative mx-auto lg:grid lg:grid-cols-6">
        <div className="pt-12 sm:pt-16 pb-12 lg:pb-0 px-4 sm:px-12 lg:col-span-3">
          <h2 className="text-2xl font-extrabold tracking-tight text-[#1B3F4B] sm:text-3xl">
            Kontakt
          </h2>
          <p className="mt-3 text-base leading-6 text-gray-500">
            Wir sind ganz in Ihrer Nähe und verfügen über ein eigenes Ladenlokal
            mit zahlreichen Artikeln zur direkten Mitnahme.
          </p>
          <div className="mt-8">
            <p className="mt-8 text-sm text-[#1B3F4B] uppercase tracking-wide font-semibold sm:mt-10">
              Standort
            </p>
            <a
              href="https://g.page/r/CaOY5q5_IyfuEBA"
              target="_blank"
              className="mt-1 sm:text-base text-sm text-gray-500 leading-6 hover:text-[#61AEC8]"
            >
              <p>Castroper Straße 43</p>
              <p className="block">45711 Datteln</p>
            </a>
            <p className="mt-8 text-sm text-[#1B3F4B] uppercase tracking-wide font-semibold sm:mt-10">
              Öffnungszeiten
            </p>
            <div className="flex mt-1 sm:text-base text-sm text-gray-500 leading-6">
              <p className="w-36">Montag - Freitag:</p>
              <p>09:00 - 18:00 Uhr</p>
            </div>
            <div className="flex sm:text-base text-sm text-gray-500 leading-6">
              <p className="w-36">Samstag:</p>
              <p>09:00 - 13:00 Uhr</p>
            </div>
          </div>

          <dl className="mt-8 sm:text-base text-sm text-gray-500">
            <div className="mt-6">
              <dt className="sr-only">Phone number</dt>
              <a
                href="tel:023637286590"
                className="flex items-center sm:text-base text-sm hover:text-[#61AEC8]"
              >
                <PhoneIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <span className="ml-3">+49 2363 7286590</span>
              </a>
            </div>
            <div className="mt-3">
              <dt className="sr-only">Email</dt>
              <a
                href="mailto:info@ortho-corp.de"
                className="flex items-center sm:text-base text-sm hover:text-[#61AEC8]"
              >
                <MailIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <span className="ml-3">info@ortho-corp.de</span>
              </a>
            </div>
          </dl>
          <div className="mt-8">
            <p className="mt-8 text-sm text-[#1B3F4B] uppercase tracking-wide font-semibold sm:mt-10">
              Google Rezensionen
            </p>
            <a
              href="https://g.page/r/CaOY5q5_IyfuEAg/review"
              target="blank_"
              className="flex items-center mt-1 sm:text-base text-sm text-gray-500 hover:text-[#61AEC8] leading-6"
            >
              <p className="w-14 sm:w-16">5 von 5</p>
              <div className="flex text-[#61AEC8]">
                <StarIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <StarIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <StarIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <StarIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <StarIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
              </div>
            </a>
          </div>
        </div>
        <div className="pb-12 sm:pb-16 lg:pt-16 px-4 sm:px-12 lg:col-span-3">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="grid grid-cols-1 gap-y-6"
          >
            <div>
              <label htmlFor="name" className="sr-only">
                Name
              </label>
              <input
                type="text"
                name="full-name"
                id="full-name"
                autoComplete="name"
                required="required"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#61AEC8] focus:border-[#61AEC8] border-gray-300 rounded-md"
                placeholder="Name"
              />
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required="required"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#61AEC8] focus:border-[#61AEC8] border-gray-300 rounded-md"
                placeholder="Email"
              />
            </div>
            <div>
              <label htmlFor="phone" className="sr-only">
                Phone
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                autoComplete="tel"
                required="required"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#61AEC8] focus:border-[#61AEC8] border-gray-300 rounded-md"
                placeholder="Telefon"
              />
            </div>
            <div>
              <label htmlFor="message" className="sr-only">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                required="required"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#61AEC8] focus:border-[#61AEC8] border border-gray-300 rounded-md"
                placeholder="Nachricht"
                defaultValue={""}
              />
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input
                  type="checkbox"
                  name="checkbox"
                  id="checkbox"
                  required="required"
                  className="focus:outline-none"
                  value="Mit den Bestimmungen zum Datenschutz bin ich einverstanden. Ich willige ein, dass Ortho Corp GmbH die von mir zur Verfügung gestellten persönlichen Daten zur Bearbeitung meiner Anfrage elektronisch verarbeitet und mich kontaktiert."
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="checkbox" class="text-sm text-gray-700">
                  Mit den Bestimmungen zum{" "}
                  <span className="text-[#61AEC8] underline hover:text-[#4098B5]">
                    <Link to="/datenschutz">Datenschutz</Link>
                  </span>{" "}
                  bin ich einverstanden. Ich willige ein, dass Ortho Corp GmbH
                  die von mir zur Verfügung gestellten persönlichen Daten zur
                  Bearbeitung meiner Anfrage elektronisch verarbeitet und mich
                  kontaktiert.
                </label>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="inline-flex justify-center py-2.5 px-8 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-[#61AEC8] hover:bg-[#4098B5] focus:outline-none"
              >
                Senden
              </button>
            </div>
            {showModal && (
              <div class="rounded-md bg-green-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg
                      class="h-5 w-5 text-green-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-green-800">
                      Ihre Nachricht wurde erfolgreich übermittelt.
                    </p>
                  </div>
                  <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                      <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none"
                      >
                        <span class="sr-only">Dismiss</span>
                        <svg
                          class="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Kontakt;
